import "./App.css";
import Home from "./components/pages/Home";
import SignUp from "./components/pages/Signup";
import About from "./About";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import SignIn from "./SignIn";
import { AuthProvider } from "./components/contexts/AuthContext";
import AuthRoute from "./components/AuthFunctions/AuthRoute";

import AuthRouteIfLoggedOut from "./components/AuthFunctions/AuthRouteIfLoggedOut";
import ForgotPassWordConfirmEmail from "./components/AccountFeatures/ForgotPassWordConfirmEmail";
import Profile from "./components/User/Profile";
import Post from "./components/Media/Post";
import SearchPage from "./components/SearchBar/SearchPage";
import RecipeCategoryAndType from "./components/Media/RecipeCategoryAndType";
import AccountSettingsPage from "./components/User/AccountSettingsPage";
import EmailVerificationPage from "./components/pages/EmailVerficationPage";
import PasswordResetConfirmation from "./components/pages/PasswordResetConfirmationPage";
import PageNotFound from "./components/pages/PageNotFound";
import LogoutPage from "./components/pages/Logout";
import DeactivateAccount from "./components/pages/DeactivateAccountPage";
import ContactPage from "./components/pages/Contact";
import OurVision from "./components/pages/OurVision";
import PrivacyPolicy from "./components/pages/PrivacyPolicyPage";
import AuthAction from "./components/pages/AuthAction";
import { useParams } from "react-router-dom";

import AddRecipeSteps from "./components/pages/AddRecipePageSteps/AddRecipeSteps";
import Newsletter from "./components/pages/Newsletter";
import { db } from "./components/Firebase/firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";

function App() {
  // const { utm_source, utm_medium, utm_campaign, utm_content } = useParams();

  // const [hasLoggedVisit, setHasLoggedVisit] = useState(false);
  // useEffect(() => {
  //   // Save each parameter if it exists and is not empty
  //   if (utm_source && utm_source.trim() !== "") {
  //     localStorage.setItem("utm_source", utm_source);
  //   }
  //   if (utm_medium && utm_medium.trim() !== "") {
  //     localStorage.setItem("utm_medium", utm_medium);
  //   }
  //   if (utm_campaign && utm_campaign.trim() !== "") {
  //     localStorage.setItem("utm_campaign", utm_campaign);
  //   }
  //   if (utm_content && utm_content.trim() !== "") {
  //     localStorage.setItem("utm_content", utm_content);
  //   }
  // }, [utm_source, utm_medium, utm_campaign, utm_content]);

  // const logVisit = async () => {
  //   try {
  //     await addDoc(collection(db, "campaign_results"), {
  //       utm_source: localStorage.getItem("utm_source") || null,
  //       utm_medium: localStorage.getItem("utm_medium") || null,
  //       utm_campaign: localStorage.getItem("utm_campaign") || null,
  //       utm_content: localStorage.getItem("utm_content") || null,
  //       timestamp: serverTimestamp(),
  //     });
  //     console.log("Visit logged to Firestore!");
  //   } catch (error) {
  //     console.error("Error logging visit: ", error);
  //   }
  // };

  // useEffect(() => {
  //   const alreadyLogged = localStorage.getItem("visit_logged");
  //   if (
  //     !alreadyLogged &&
  //     !hasLoggedVisit(utm_source && utm_source.trim()) !== ""
  //   ) {
  //     localStorage.setItem("visit_logged", "true");
  //     setHasLoggedVisit(true);

  //     logVisit();
  //   }
  // }, []);
  return (
    <Router>
      <div className="App">
        <AuthProvider>
          <Routes>
            <Route path="*" element={<PageNotFound />} />
            <Route path="/" element={<Home />} />
            <Route exact path="/newsletter" element={<Newsletter />} />
            <Route exact path="/auth/action" element={<AuthAction />} />
            <Route element={<AuthRoute />}>
              {/* 
          If user is already signed in re-direct to home
          */}

              <Route exact path="/sign_up" element={<SignUp />} />
              <Route exact path="/login" element={<SignIn />} />
              <Route
                exact
                path="/auth/reset-password-request"
                element={<ForgotPassWordConfirmEmail />}
              />
            </Route>
            {/*
        If a user is logged out re-direct. That's what AuthRouteIfLoggedOut applies. 
        */}
            <Route element={<AuthRouteIfLoggedOut />}>
              <Route exact path="/new_post" element={<AddRecipeSteps />} />

              <Route exact path="profile/:username" element={<Profile />} />

              <Route
                exact
                path="profile/accounts/:edit"
                element={<AccountSettingsPage />}
              />
              <Route
                exact
                path="/profile/accounts/deactivate"
                element={<DeactivateAccount />}
              />
            </Route>

            {/*
   No Auth Validation required -------------------------------------
      */}
            <Route exact path="post/:category/:recipe" element={<Post />} />
            <Route
              exact
              path="/email-verification"
              element={<EmailVerificationPage />}
            />
            <Route exact path="/pages/contact" element={<ContactPage />} />
            <Route exact path="/pages/about" element={<About />} />
            <Route exact path="/search" element={<SearchPage />} />
            <Route exact path="/pages/our-vision" element={<OurVision />} />
            <Route
              exact
              path="/pages/privacy-policy"
              element={<PrivacyPolicy />}
            />
            {/*
        
       */}
            <Route
              exact
              path="/reset-password"
              element={<PasswordResetConfirmation />}
            />
            <Route
              exact
              path="explore/:recipe_category/:recipe_type"
              element={<RecipeCategoryAndType />}
            />
            <Route exact path="/logout" element={<LogoutPage />} />
          </Routes>
        </AuthProvider>
      </div>
    </Router>
  );
}

export default App;
