import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { db, storage } from "./firebase";
import { CategoryTagsData } from "../DataVariables/CategoryTagsData";
import { message } from "antd";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  increment,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";

export async function AddPostFirestore(data, currentUser) {
  if (!currentUser || !currentUser.uid) {
    throw new Error(
      "Please log in to add a post or there is something wrong with the user's permission please contact the developer"
    );
  }

  const userDoc = await getDoc(doc(db, "users", currentUser.uid));

  if (!userDoc.exists) {
    throw new Error("User not found");
  }

  const userData = userDoc.data();
  const getUserUsername = userData.username || "";
  const internationalCusinsine = data.tags.some((item) =>
    CategoryTagsData.includes(item)
  );

  const getNewMappedCalorieList = data.ingredients.reduce(
    (previousScore, currentScore) =>
      previousScore + Number(currentScore.calories),
    0
  );

  if (!data.imageUrl) {
    throw new Error(
      "There was a problem with adding your image. Please try to re-add it or contact support."
    );
  }
  const file = data.imageUrl;

  const metadata = {
    contentType: "image/jpeg",
  };
  const uploadImageRef = ref(storage, `UserAddedPosts/${data.title}`);
  const uploadImage = uploadBytesResumable(
    uploadImageRef,
    data.imageUrl,
    metadata
  );

  const url = await new Promise((resolve, reject) => {
    uploadImage.on(
      "state_changed",
      (snapshot) => {
        const progressPercent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        message.log(`Upload is ${progressPercent}% done`);
      },
      (err) => {
        reject(new Error("Image upload failed: " + err.message));
      },
      async () => {
        const downloadUrl = await getDownloadURL(uploadImage.snapshot.ref);
        resolve(downloadUrl);
      }
    );
  });

  await addDoc(collection(db, "posts"), {
    id_of_user: currentUser.uid,
    email: currentUser.email,
    title: data.title,
    desc: data.desc,
    privatePosts: userData.privateProfile,
    allowComments: userData.allowComments,
    tags: data.tags,
    category: data.category,
    calorieTotal: getNewMappedCalorieList,
    ingredients: data.ingredients,
    imageUrl: url,
    international: internationalCusinsine,
    instructionsForRecipe: data.instructionsForRecipe,
    username_of_user: getUserUsername,
    rating: 0,
    createdAt: serverTimestamp(),
  });

  await updateDoc(doc(db, "users", currentUser.uid), {
    dailyAmountOfPostsCount: increment(1),
  });
}
