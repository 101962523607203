import React from "react";

import { useAuth } from "../contexts/AuthContext";
import Navbar from "../Navbar/Navbar";
import UserSignedIn from "../Navbar/UserSignedIn";
import { useNavigate } from "react-router-dom";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import CustomForm from "../utils/CustomForm";
import { Mail } from "@mui/icons-material";

export default function Newsletter() {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [submissionSuccess, setSubmissionSuccess] = React.useState(null);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData(event.target);
    const url = event.target.action; // URL from the action attribute of the form

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      if (response.ok) {
        // Successful submission
        setSubmissionSuccess(true);
        navigate("/success_page?status=success");
      } else {
        // Handle errors, for example, show a message to the user
        setSubmissionSuccess(false);
        navigate("/current_page?status=failure");
      }
    } catch (error) {
      // Handle network errors
      setSubmissionSuccess(false);
      navigate("/current_page?status=failure");
    } finally {
      setIsSubmitting(false);
    }
  };

  const mailchimpUrl =
    "https://app.us12.list-manage.com/subscribe/post?u=d2efa1212ff18f337c2aa30ce&amp;id=195eb4aefa";
  return (
    <>
      {!currentUser ? <Navbar /> : <UserSignedIn />}
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-blue-50 to-purple-50">
        <div className="max-w-3xl mx-auto p-6 w-full">
          <div className="bg-white rounded-2xl shadow-xl p-8 md:p-12">
            <div className="flex flex-col items-center space-y-6">
              {/* Icon */}
              <div className="bg-purple-100 p-4 rounded-full">
                <Mail className="w-8 h-8 text-purple-600" />
              </div>

              {/* Header */}
              <div className="text-center space-y-4">
                <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-gray-900">
                  Receive Acid-Reflux Friendly Recipes
                </h2>
                <p className="text-gray-600 max-w-2xl mx-auto text-base md:text-lg">
                  Sign up to get our best GERD, acid-reflux, and gastritis
                  friendly recipes, cooking tips, must-have kitchen products and
                  news — delivered straight to your inbox.
                </p>
              </div>

              {/* Community Banner */}
              <div className="bg-purple-50 w-full p-6 rounded-xl text-center">
                <p className="text-gray-700 text-base md:text-lg">
                  Join our growing community of over 100+ people passionate
                  about gastritis-friendly recipes!{" "}
                  <button
                    onClick={() => navigate("/sign_up")}
                    className="text-purple-600 hover:text-purple-700 font-medium underline decoration-2 decoration-purple-300 hover:decoration-purple-500 transition-colors duration-200"
                  >
                    Sign up to our platform
                  </button>
                </p>
              </div>

              {/* Mailchimp Form */}
              <div className="w-full">
                <MailchimpSubscribe
                  url={mailchimpUrl}
                  render={({ subscribe, status, message }) => (
                    <CustomForm
                      status={status}
                      formMessage={message}
                      onValidated={(formData) => subscribe(formData)}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
